import { parse } from 'cookie'
import '@ant-design/v5-patch-for-react-19'
import { lazy, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { VisitorProvider } from './hooks/use-visitor'
import { getUserFromToken } from './helpers/profile'
import { ConfigProvider } from 'antd'


const App = lazy(() => import('./App'))

function ready(fn) {
  if (document.readyState != 'loading') {
    fn()
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

ready(async () => {
  const root = createRoot(document.getElementById('root'))
  // "Bearer XXXXX"
  let storedToken = localStorage.getItem('token')
  if (!storedToken) {
    const cookies = parse(document.cookie)
    if (cookies.token) {
      storedToken = `Bearer ${cookies.token}`
    }
  }
  const visitor = await getUserFromToken(storedToken)
  root.render((
    <ConfigProvider theme={{
      token: { colorPrimary: "#000000" },
      components: {
        Menu: {
          itemSelectedColor: "#ffffff",
          horizontalItemSelectedColor: "#ffffff",
          subMenuItemSelectedColor: "#ffffff",
        },
        Select: {
          optionSelectedBg: "rgba(0,0,0,0.06)"
        }
      }
    }} renderEmpty={() => (<h3>Не найдено</h3>)}>
      <VisitorProvider initialUser={visitor}>
        <BrowserRouter>
          <Suspense fallback={
            <div className='loadingWrap'>
              <div className='loadingBoxes loadingBoxColour1'></div>
              <div className='loadingBoxes loadingBoxColour2'></div>
              <div className='loadingBoxes loadingBoxColour3'></div>
              <div className='loadingBoxes loadingBoxColour4'></div>
              <div className='loadingBoxes loadingBoxColour5'></div>
            </div>}>
            <App />
          </Suspense>
        </BrowserRouter>
      </VisitorProvider>
    </ConfigProvider>
  ))
})
